<template>
  <th class="nk-tb-col" :class="classesList">
    <slot></slot>
  </th>
</template>

<script>
export default {
  name: "TbTh",
  props: {
    size: String
  },
  setup(props){
    let classesList=  []
    if(props.size){
      classesList.push(`tb-col-${props.size}`)
    }
    return {
      classesList
    }
  }
}
</script>

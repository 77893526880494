<template>
  <table class="datatable-init nowrap nk-tb-list is-separate" data-auto-responsive="false">
    <slot></slot>
  </table>
</template>

<script>
//import {onMounted} from 'vue';

export default {
  setup() {

  },
}
</script>

<template>
  <ul class="nk-tb-actions gx-1 my-n1">
    <slot></slot>
  </ul>
</template>

<script>
export default {

}
</script>

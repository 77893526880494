<template>
  <td class="nk-tb-col" :class="classesList">
    <slot></slot>
  </td>
</template>

<script>
export default {
  name: "TbTd",
  props: {
    size: String,
  },
  setup(props){
    let classesList=  []
    if(props.size){
      classesList.push(`tb-col-${props.size}`)
    }
    return {
      classesList
    }
  }
}
</script>
